.Top{
  align-items: center;
}

.Top-section{
  margin: 0 auto;
  max-width: 1200px;
}

.Top-button {
  margin: 10px 0px;
}

.Top-top-title{
  text-align: center;
  margin: 40px 0px;
}
.Top-top-body{
  text-align: left;
}

.Top-tos-label {
  display: flex; 
  align-items: center;
  justify-content: center;
  flex-direction: row;

  margin: 0 auto;
  padding: 24px 0px 0px 10px;
}
.Top-tos-text {
  text-align: left;
  flex: 1;
}
.Top-tos-checkbox:checked {
  border: 6px solid #4F5B6D;
}
.Top-tos-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: 0.2s all linear;
  border-radius: 20%;
  width: 20px;
  height: 20px;

  border: 2px solid #4F5B6D;
  margin: 4px 12px 4px 4px;

  position: relative;
  top: 0px;
}
.Top-tos-checkbox:checked {
  border: 6px solid #4F5B6D;
}
.Input-error {
  color: red;
}