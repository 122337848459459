
.InputField {
  padding: 32px 0px 0px 0px;
  margin: 0px 32px;
}

.InputField-title {
  text-align: left;
  vertical-align: center;
  padding: 0px 8px;
  margin: auto 0px;
}

.InputField-must {
  background-color: #1C7AAC;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 3px 7px;
  margin: 0px 8px 0px 0px;
  /* line-height: 1; */
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
}

.InputField-input {
  width: 100%;
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 3px;
  border: 2px solid #ddd;
  box-sizing: border-box;
}
.InputField-input:focus {
  border: 2px solid #ff9900; 
  z-index: 10;
  outline: 0;
}