.Header{
  margin: 0px;
}

.Header-section{
  margin: 0 auto;
  max-width: 1400px;
  max-height:80px;
}

.Header-logo{
  display:block;
  margin: 22px auto 22px 40px;
  width: 180px;
}