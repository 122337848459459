.Radio {
  text-align: center;
}

.Radio-link {
  color: #61dafb;
}

.Radio-ul {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;

  list-style: none;
  text-align: left;
  border-radius: 10px;
  background-color: #E0E3E9;
}

.Radio-category {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  /* margin-top: 16px; */
  margin-bottom: 16px;

  font-size: calc(10px + 1.5vmin);
}
.Radio-question {
  display: block;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-top: 0px;
  margin-bottom: 16px;

  font-size: calc(8px + 1.5vmin);
}
.Radio-label {
  display: flex; 
  align-items: center;
}
.Radio-answertext {
  text-align: left;
  flex: 1;
}
.Radio-input-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: 0.2s all linear;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  border: 2px solid #4F5B6D;
  margin: 4px 12px 4px 4px;

  position: relative;
  top: 0px;
}
.Radio-input-radio:checked {
  border: 6px solid #4F5B6D;
}

.Radio-remarks {
  margin-top: 16px;
  margin-bottom: 16px;

  font-size: calc(9px + 1vmin);
}
