.TermsOfService{
  margin: 0 16px;
}
.TermsOfService-markdown{
  height: 350px;
  overflow-y: scroll;

  font-size: calc(4px + 1vmin);
  text-align: left;
}
