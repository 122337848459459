
.TitleBar {
  margin: 16px 0px;
}

.TitleBar-title {
  color: white;
  background-color: #0F203E;
  /* max-width: 100vw; */
  padding: 24px 24px;
  margin: 0px 0px;
  text-align: center;
}

.TitleBar-subtitle {
  /* color: white; */
  /* background-color: #0F203E; */
  /* max-width: 100vw; */
  padding: 16px 16px;
  margin: 0px 0px;
  border-bottom: #0F203E 3px solid;
  text-align: center;
}